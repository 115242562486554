import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { useState } from "react";
import { MosqueType } from ".";

interface SelectMosqueProps {
    mosqueList: MosqueType[]
    setMosqueId: React.Dispatch<React.SetStateAction<string>>
    setMosqueName: React.Dispatch<React.SetStateAction<string>>
}

export const SelectMosque = ({mosqueList, setMosqueId, setMosqueName}: SelectMosqueProps) => {
    
    // Is Modal open
    const [open, setOpen] = useState(false);

    // Preparing options for Autocomplete options list
    const options: {label: string, id: string}[] = [];
    mosqueList.forEach((mosque) => {
        options.push({
            label: mosque.name,
            id: mosque._id
        })
    });

    return(
        <>
            <Button 
                variant={"outlined"}
                onClick={() => setOpen(true)}
                sx={{
                    color: "#FFFFFF",
                    borderColor: "#FFFFFF",
                    borderRadius: "50px",
                    borderWidth: "2px",
                    fontSize: {xs: "18px", md: "24px"},
                    ":hover": {
                        borderColor: "#FFFFFF",
                        borderWidth: "2px"
                    },
                    px: "30px",
                    maxHeight: "64px",
                    maxWidth: "233px"
                }}
            >
                Select Mosque
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Select Mosque</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        sx={{ 
                            width: {md: 300}, 
                            mt: 2,
                        }}
                        disablePortal={false}
                        id="mosque-select"
                        options={options}
                        // defaultValue={returnStoredMosque()}
                        renderInput={(params) => (
                            <TextField 
                                {...params} 
                                label="Mosque" 
                                sx={{
                                    "& label.Mui-focused": {
                                        color: "gray",
                                    },
                                    "& .MuiInput-underline:after": {
                                        borderBottomColor: "gray",
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: 'gray',
                                        },
                                        "&:hover fieldset": {
                                            borderColor: 'gray',
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: "gray",
                                        },
                                    }
                                }}
                            />
                        )}
                        onChange={(_event ,value) => {
                            if (value) {
                                setMosqueId(value.id);
                                setMosqueName(value.label);
                                localStorage.setItem("mosque", JSON.stringify(value));
                                setOpen(false);
                            }
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setOpen(false)}
                        sx={{
                            color: "black"
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
