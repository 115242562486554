import { Typography } from "@mui/material";
import { useState } from "react";

export const CurrentTime = () => {
    const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}));

    const updateTime=()=>{
        const time =  new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
        setCurrentTime(time)
    }
    setInterval(updateTime);

    return (
        <Typography 
            color={"#FFFFFF"} 
            fontSize={{xs: "123px", md: "246px"}} 
            py={0}
        >
            {currentTime}
        </Typography>
    )
}