import { CalendarMonth } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { Moment } from "moment"
import { useState } from "react"

interface DatePickerButtonProps {
    selectedDate: Moment,
    setSelectedDate: React.Dispatch<React.SetStateAction<Moment>>
}

export const DatePickerButton = ({selectedDate, setSelectedDate}: DatePickerButtonProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any) => {
      setIsOpen((isOpen) => !isOpen);
      setAnchorEl(event.currentTarget);
    };

    return (
        <DatePicker
            open={isOpen}
            onClose={() => setIsOpen(false)}
            value={selectedDate}
            onChange={(value) => {
                if (value) {
                    setSelectedDate(value)
                }
            }}
            PopperProps={{
                placement: "bottom-end",
                anchorEl: anchorEl,
                sx: {
                    "& .MuiPickersDay-root": {
                        "&.Mui-selected": {
                            backgroundColor: "#DF3C41",
                            color: "#FFFFFF"
                        },
                    },

                }
              }}
            renderInput={() => (
                <IconButton 
                    onClick={handleClick}
                    sx={{
                        ":hover": {
                            backgroundColor: "#DF3C41"
                        },
                        p: 0
                    }}
                >
                    <CalendarMonth 
                        color={"primary"} 
                        sx={{fontSize: {xs: "20px", md: "35px"}}}
                    />
                </IconButton>
              )}
      />
    )
}
