import { Box, Stack, Typography } from "@mui/material";
import { Moment } from "moment";
import { DatePickerButton } from "../../components/DatePickerButton";
import { CurrentTime } from "./CurrentTime";

interface DateSelectProps {
    dateSelect: Moment
    setDateSelect: React.Dispatch<React.SetStateAction<Moment>>
    mosqueName: string
}

export const DateSelect = ({dateSelect, setDateSelect, mosqueName}: DateSelectProps) => {

    return (
        <Box 
            sx={{
                display: "flex",  
                justifyContent: "center",
            }}
            pb={6}
        >  
            <Stack alignItems={"center"}>
                <Typography color={"#FFFFFF"} fontSize={{xs: "16px", md: "32px"}} >{mosqueName.toLocaleUpperCase()}</Typography>
                <CurrentTime />
                <Box
                    sx={{
                        display: "flex",  
                        justifyContent: "center",
                    }}
                >
                    <Typography 
                        color={"#FFFFFF"} 
                        fontSize={{xs: "21px", md: "42px"}} 
                        paddingRight={{xs: "10px", md:"20px"}}
                    >
                        {dateSelect.format("dddd, MMMM Do YYYY")}
                    </Typography>
                    <DatePickerButton 
                        selectedDate={dateSelect}
                        setSelectedDate={setDateSelect}
                    />
                </Box>
            </Stack>
        </Box>
    )
}
