import { Box, Stack, Tab, Tabs, Theme, Typography, useMediaQuery } from "@mui/material";
import axios from "axios";
import { Moment } from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { TabPanel } from "../../components/TabPanel";

interface TimetableProps {
    mosqueId: string
    date: Moment
}

interface PrayerTimesType {
    _id: string
    mosque_id: string
    date: string
    sunrise: string
    fajr: string
    fajr_jamaah: string
    dhuhr: string
    dhuhr_jamaah: string
    asr: string
    asr_2: string
    asr_jamaah: string
    maghrib: string
    maghrib_jamaah: string
    isha: string
    isha_jamaah: string
}

export const Timetable = ({mosqueId, date}: TimetableProps) => {

    const [value, setValue] = useState<number>();
    const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    const dateString = date.toISOString().replace(/T.*/,'');
    const { isLoading, error, data, isSuccess } = useQuery(["times", mosqueId, date], () =>
        axios.get(
            `https://app.salah-companion.co.uk/api/v1/prayerTimes?mosqueId=${mosqueId}&date=${dateString}`
        ).then((res) => res.data)
    );

    useEffect(() => {
        if (isSuccess) {
            setValue(getDefaultTab(data.prayerTimes[0]))
        }
    }, [data, isSuccess]);

    if (isLoading || value === undefined) return (
        <></>
    );

    if (error) return (
        <>An error has occurred</>
    )

    const prayerTimes: PrayerTimesType = data.prayerTimes[0]
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return(
        <Box>  
            <Stack direction={{xs: "row", md: "column"}}>
                <Box 
                    sx={{
                        backgroundColor: "#CC363A", 
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: {xs: "0px", md: "20px"},
                        borderBottomLeftRadius: {xs: "20px", md: "0px"}
                    }}
                >
                <Tabs 
                    value={value} 
                    onChange={handleChange} 
                    aria-label={"prayer times tabs"}
                    orientation={matches ? "horizontal" : "vertical"}
                    indicatorColor={"secondary"}
                > 
                    <Tab label="Fajr" value={0} />
                    <Tab label="Sunrise" value={1} />
                    <Tab label="Dhuhr" value={2} />
                    <Tab label="Asr" value={3} />
                    {prayerTimes.asr_2 &&
                        <Tab label="Asr 2" value={4} />
                    }
                    <Tab label="Maghreb" value={5} />
                    <Tab label="Isha" value={6} />
                </Tabs>
                </Box>
                <Box 
                    sx={{
                        display: "flex", 
                        flexDirection: "column",
                        justifyContent: "center",
                        color: "white",
                        backgroundColor: "#F2464B",
                        borderBottomLeftRadius: {xs: "0px", md: "20px"},
                        borderBottomRightRadius: "20px",
                        borderTopRightRadius: {xs: "20px", md: "0px"},

                    }}
                >
                    <TabPanel value={value} index={0}>
                        {displayPrayerTime("fajr", prayerTimes, "fajr_jamaah")}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {displayPrayerTime("sunrise", prayerTimes)}
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {displayPrayerTime("dhuhr", prayerTimes, "dhuhr_jamaah")}
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        {displayPrayerTime("asr", prayerTimes, "asr_jamaah")}
                    </TabPanel>
                    {prayerTimes.asr_2 &&                    
                        <TabPanel value={value} index={4}>
                            {displayPrayerTime("asr_2", prayerTimes, "asr_jamaah")}
                        </TabPanel>
                    }
                    <TabPanel value={value} index={5}>
                        {displayPrayerTime("maghrib", prayerTimes, "maghrib_jamaah")}
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                        {displayPrayerTime("isha", prayerTimes, "isha_jamaah")}
                    </TabPanel>
                </Box>
            </Stack>
        </Box>
    )
}

const displayPrayerTime = (
    prayer: keyof PrayerTimesType, 
    prayerTimes: PrayerTimesType,
    jamaahTime?: keyof PrayerTimesType, 
) => {

    return (
        <Box 
            sx={{
                display: "flex", 
                alignItems: "center",
                justifyContent: "center", 
                flexDirection: {xs: "column", md: "row"},
            }}
        >
            <Typography 
                fontSize={"28px"} 
                mx={{xs: 2, md: 4}}
                my={{xs: 4, md: 0}}
            >
                Start: {convertTo24HourFormat(prayer, prayerTimes[prayer])}
            </Typography>
            {jamaahTime ? 
                <Typography 
                    fontSize={"28px"} 
                    mx={{xs: 2, md: 4}}
                    my={{xs: 4, md: 0}}
                >
                    Jama'ah: {convertTo24HourFormat(prayer, prayerTimes[jamaahTime])}
                </Typography> : <></>}
        </Box>
    )
}

const convertTo24HourFormat = (prayerName: keyof PrayerTimesType, time: string) => {
    const hoursString = time.split(":")[0];
    const minutes = time.split(":")[1];
    const hoursNumber = Number(hoursString);

    if(prayerName === "fajr" || prayerName === "sunrise") {
        return time;
    } else if (prayerName === "dhuhr") {
        if (hoursNumber >= 1 && hoursNumber < 9) {
            return `${hoursNumber + 12}:${minutes}`;
        } else {
            return time;
        }
    }

    return `${hoursNumber + 12}:${minutes}`;
}

const getDefaultTab = (prayerTimes: PrayerTimesType) => {
    const currentDate = new Date().toDateString();
    const currentTime = new Date();
    let asrTime: Date;

    // Use asr 2 if it exists, if not use asr
    if (prayerTimes.asr_2) {
        asrTime = new Date(currentDate + " " + convertTo24HourFormat("asr_2", prayerTimes.asr_2))
    } else {
        asrTime = new Date(currentDate + " " + convertTo24HourFormat("asr", prayerTimes.asr))
    }


    if (currentTime > new Date(currentDate + " " + convertTo24HourFormat("isha", prayerTimes.isha))) {
        return 0; // default to fajr
    } else if (currentTime > new Date(currentDate + " " + convertTo24HourFormat("maghrib", prayerTimes.maghrib))) {
        return 6; // default to isha
    } else if ((currentTime > asrTime)) {
        return 5; // default to maghrib
    } else if ((currentTime > new Date(currentDate + " " + convertTo24HourFormat("dhuhr", prayerTimes.dhuhr)))) {
        if (prayerTimes.asr_2) {
            return 4; // default to asr 2
        } else {
            return 3; // default to asr
        }
    } else if ((currentTime > new Date(currentDate + " " + convertTo24HourFormat("fajr", prayerTimes.fajr)))) {
        return 2; // default to dhuhr
    }

    return 0;
}