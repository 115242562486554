import { GlobalStyles, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HomePage } from './pages/homePage';
import { theme } from './theme/theme';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';


const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
      <GlobalStyles
          styles={{
            body: { backgroundColor: "#DF3C41" },
          }}
      />
      <ThemeProvider theme={theme}>
        <HomePage />
      </ThemeProvider>  
      </LocalizationProvider>
    </QueryClientProvider>
  );
}

export default App;
