import { Box, Stack } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react"
import { useQuery } from "react-query";
import { DateSelect } from "./DateSelect";
import { SelectMosque } from "./SelectMosque"
import { Timetable } from "./Timetable";
import { ReactComponent as LogoSvg } from "../../images/Logo.svg";
import moment from "moment";

export const HomePage = () => {
    const [mosqueId, setMosqueId] = useState("5fc3dbd5b3c5b1151b40d30f"); // id for elm
    const [mosqueName, setMosqueName] = useState<string>("");
    const [dateSelect, setDateSelect] = useState(moment());
    
    const { isLoading, error, data, isSuccess } = useQuery("mosqueList", () =>
        axios.get(
            "https://app.salah-companion.co.uk/api/v1/mosques"
        ).then((res) => res.data)
    );

    useEffect(() => {
        const storedMosqueString = localStorage.getItem("mosque");
        if (isSuccess) {
            if(storedMosqueString) {
                const storedMosque: {label: string, id: string} = JSON.parse(storedMosqueString);
                setMosqueId(storedMosque.id)
                setMosqueName(storedMosque.label);
            } else {
                setMosqueName(data.mosques[0].name);
            }
        }
    }, [data, isSuccess, mosqueId])

    if (isLoading) return (
        <></>
    );

    if (error) return (
        <>"An error has occurred"</>
    )

    return (
        <Box>
            <Box sx={{display: "flex", justifyContent: "space-between", m: 5}} >
                <LogoSvg height={"90px"}/>
                <SelectMosque 
                    mosqueList={data.mosques} 
                    setMosqueId={setMosqueId}
                    setMosqueName={setMosqueName}
                />
            </Box>
            <Box 
                sx={{
                    display: "flex",  
                    justifyContent: "center"
                }}
            >
                <Stack>
                    <DateSelect 
                        dateSelect={dateSelect} 
                        setDateSelect={setDateSelect}
                        mosqueName={mosqueName}
                    />
                    <Timetable mosqueId={mosqueId} date={dateSelect}/>
                </Stack>
            </Box>
        </Box>
    )
}

export interface MosqueType {
    _id: string
    code: string
    name: string
    contact: {
        address_line_1: string
        address_line_2: string
    }
}