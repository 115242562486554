import { colors, createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
      primary: {
        main: "#FFFFFF"
      },
      secondary: colors.yellow
    },
    typography: {
      fontFamily: "Inter",
      allVariants: {
        lineHeight: 1.2,
        textTransform: "none"
      },
    },
    components: {
      MuiTab: {
        defaultProps: {
          style: {color: "#FFFFFF", fontSize: "32px", paddingLeft: "24px", paddingRight: "24px"}
        }
      },
      
    }
  })